import { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Chip } from "@mui/material";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import UnreadMessageBadge from "shared/components/CollapsibleTable/UnreadMessageBadge";
import { generateOrderBy } from "shared/components/CollapsibleTable/utils";
import DataGridTable from "shared/components/DataGridTable/DataGridTable";
import {
  createStyledLinkCell,
  renderVehicleImageCell,
  useDataGridSortHandler,
} from "shared/components/DataGridTable/utils";
import { Order_By, useGetAllRequestsShopQuery } from "shared/generated/graphql";
import {
  getPrettyTime,
  prettyYearMakeModel,
  useDebounce,
} from "shared/lib/utils";
import { useAuth } from "shared/providers/AuthProvider";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { getSearchFilters } from "./requestUtils";
import { ctaStatuses, statusTextMap } from "./util";

export default function RequestsDataGridDashboard() {
  const tableApiRef = useGridApiRef();
  const { shopId } = useAuth();
  const { getUnreadMessagesCount, setRequestIds } = useUnreadRequests();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [yesterday] = useState(
    () => new Date(Date.now() - 24 * 60 * 60 * 1000)
  );

  const { sortField, sortDirection, handleSortModelChange } =
    useDataGridSortHandler({ field: "id", direction: Order_By.Desc });

  const { data, loading } = useGetAllRequestsShopQuery({
    variables: {
      shopId: shopId ?? 0,
      filter: getSearchFilters(debouncedSearchTerm),
      orderBy: generateOrderBy(sortField, sortDirection),
      datesForClosed: yesterday,
    },
    pollInterval: 10_000,
    skip: !shopId,
  });

  useEffect(() => {
    if (!data?.requests.length) return;
    const requestIds = data?.requests?.map((request) => request.id);
    setRequestIds(requestIds);
  }, [data?.requests, setRequestIds]);

  const columns: GridColDef<(typeof rows)[number]>[] =
    useMemo(() => {
      return [
        {
          field: "image",
          headerName: "",
          sortable: false,
          renderCell: renderVehicleImageCell,
        },
        {
          field: "id",
          headerName: "Order",
          hideable: false,
          renderCell: (params) => {
            const LinkCell = createStyledLinkCell(
              (params) => `/requests/${params.value}`
            );
            return (
              <UnreadMessageBadge
                content={LinkCell(params)}
                unreadMessageCount={getUnreadMessagesCount(params.value)}
              />
            );
          },
        },
        { field: "vehicle.year", headerName: "YMM" },
        { field: "vehicle.plate", headerName: "License Plate" },
        { field: "vehicle.mileage", headerName: "Mileage" },
        { field: "createdAt", headerName: "Date & Time" },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          renderCell: (params: GridRenderCellParams) =>
            ctaStatuses?.includes(params.value) ? (
              <Button
                variant="contained"
                size="small"
                href={`requests/${params.row.id}`}
              >
                {statusTextMap.get(params.value)}
              </Button>
            ) : (
              <Chip
                variant="outlined"
                size="small"
                label={statusTextMap.get(params.value)}
              />
            ),
        },
      ];
    }, [getUnreadMessagesCount]) ?? [];

  const rows =
    data?.requests?.map((request) => ({
      image: {
        vehicleId: request?.vehicle?.id,
        imageUrl: request?.vehicle?.imageUrl,
      },
      id: request?.id,
      "vehicle.year": prettyYearMakeModel(
        request?.vehicle.year,
        request?.vehicle.make,
        request?.vehicle.model
      ),
      "vehicle.plate": request?.vehicle?.plate,
      "vehicle.mileage": request?.vehicle?.mileage,
      createdAt: getPrettyTime(request?.createdAt),
      status: request?.status,
    })) ?? [];

  const handleFilterModelChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      const newTerm = gridFilterModel?.quickFilterValues?.[0];
      setSearchTerm(newTerm ?? "");
    },
    []
  );

  return (
    <DataGridTable
      apiRef={tableApiRef}
      columns={columns}
      rows={rows}
      loading={loading}
      handleSortChange={handleSortModelChange}
      toolbarFilter={{ handleFilterChange: handleFilterModelChange }}
      pinnedColumns={{ right: ["status"] }}
    />
  );
}
